import React, { Component } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Link, injectIntl } from "gatsby-plugin-intl";
import { useSpring, animated } from "react-spring";
// import Grid from "styled-components-grid";
// import Flickity from "react-flickity-component";

import {
  // mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockText from '../components/block-text'

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import BorderGrid, { BorderGridCol } from "../components/border-grid";
import Card from "../components/card";
import Content, { ContentContainer, ContentDivider, ContentImage, ContentImageContainer } from "../components/content";
// import GridLines from "../components/gridlines";
// import { Heading2, Heading3 } from "../components/heading";
import Mono, { MonoHeading2 } from "../components/mono";
import RatioBox from "../components/ratio-box";
import SizeAware from "../components/size-aware";
import Footer from "../components/footer";

import LetterSvg from "../images/letters3.svg";
import Logo from "../images/huiput-final.svg";

export const query = graphql`
  fragment HomeSpeakers on SanitySpeaker {
    id
    slug {
      current
    }
    title {
      fi
      en
    }
    author
    publishedAt
    listingImage {
      _key
      _type
      caption
      alt
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
        id
        url
        fixed {
          aspectRatio
          width
          height
          src
          srcSet
          srcWebp
          srcSetWebp
        }
        fluid {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    _rawBody
    website
    _rawEmbedCode
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      _rawDescription
      keywords
    }
    homepage: sanityHomepage {
      id
      _rawHomepageTitle
      _rawHomepageBody 
      homepageImage {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
    keynoteSpeakers: allSanitySpeakerListing {
      edges {
        node {
          id
          keynoteSpeakers {
            ...HomeSpeakers
          }
          
        }
      }
    }
    speakers: allSanitySpeakerListing {
      edges {
        node {
          id
          speakers {
            ...HomeSpeakers
          }
        }
      }
    }
  }
`;

const StyledOverflow = styled.div`
  overflow: hidden;
  margin-bottom: 2px;
  padding-right: 1px;
`;

const IndexPage = ({ intl, ...props }) => {
  const { data, errors } = props;
  const locale = intl.locale || "en";

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  const keynoteSpeakerNodes = (data || {}).keynoteSpeakers
    ? data.keynoteSpeakers.edges[0].node.keynoteSpeakers
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const speakerNodes = (data || {}).speakers
    ? data.speakers.edges[0].node.speakers
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <React.Fragment>
      <SEO
        title={site.title}
        description={site._rawDescription[locale]}
        keywords={site.keywords}
        lang={locale}
      />
      <StyledBorderGridWrapper>
        <BorderGrid>
          <BorderGridCol size={{ xs: 1 / 1, lg: 1 / 2 }}>
            <StyledOverflow>
              <RatioBox ratio="1/1">
                <LetterAnimations headerHeight={props.headerHeight} />
              </RatioBox>
            </StyledOverflow>
          </BorderGridCol>
          <BorderGridCol size={{ xs: 1 / 1, lg: 1 / 2 }}>
            <StyledRatioBoxWrapper>
              <RatioBox ratio="1/1" flex={true} below={true} bgColor="var(--color-pink)" color="var(--color-black)">
                <Content>
                  <MonoHeading2 wrap={true}>
                    <div>{data.homepage._rawHomepageTitle[intl.locale]} {intl.messages.date}</div>
                  </MonoHeading2>
                </Content>
              </RatioBox>
            </StyledRatioBoxWrapper>
          </BorderGridCol>
        </BorderGrid>
      </StyledBorderGridWrapper>
      <ContentDivider />
      <BorderGrid>
        {(keynoteSpeakerNodes && keynoteSpeakerNodes.length) && (
          <React.Fragment>
            {keynoteSpeakerNodes.map((node, index) => {
              return (
                <BorderGridCol key={`node-${node.id}`} size={{ xs: 1 / 1, sm: 1 / 2 }}>
                  <CardComponent node={node} locale={locale} />
                </BorderGridCol>
              )
            })}
          </React.Fragment>
        )}
        {(speakerNodes && speakerNodes.length) && (
          <React.Fragment>
            {speakerNodes.slice(0, 3).map((node, index) => {
              return (
                <BorderGridCol key={`node-2-${node.id}`} size={{ xs: 1 / 1, sm: 1 / 2 }}>
                  <CardComponent node={node} locale={locale} />
                </BorderGridCol>
              )
            })}
          </React.Fragment>
        )}
        <BorderGridCol size={{ xs: 1 / 1, sm: 1 / 2 }}>
          <StyledProgramme to={`/programme/`}>
            <RatioBox ratio="881/512" flex={true} hoverBgColor="var(--color-pink)" color="var(--color-black)">
              <StyledProgrammeContent href="programme/">
                <MonoHeading2>{intl.messages.menu_programme}<br />→</MonoHeading2>
              </StyledProgrammeContent>
            </RatioBox>
          </StyledProgramme>
        </BorderGridCol>
      </BorderGrid>
      <ContentDivider />
      <Content>
        <Content>
          <ContentContainer>
            <Mono>
              <BlockText blocks={data.homepage._rawHomepageBody[intl.locale]} />
            </Mono>
          </ContentContainer>
        </Content>
      </Content>
      <ContentDivider />
      <RatioBox ratio="1512/566" flex={true} bgColor="var(--color-pink)" color="var(--color-black)">
        <Content>
          <StyledMonoHeading2>{intl.messages.buy_tickets_1}<br /><a href="https://www.grafia.fi/kauppa/" target="_blank">{intl.messages.buy_tickets_2}</a></StyledMonoHeading2>
        </Content>
      </RatioBox>
      <ContentDivider />
      <Footer />
    </React.Fragment >
  );
};

const StyledMonoHeading2 = styled(MonoHeading2)`
  margin: 0 !important;
`;

const StyledProgramme = styled(Link)`
  display: block;
  position: relative;
  height: 100%;

  &:hover:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--color-pink);
  }
`;

const StyledProgrammeContent = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100% + 51px);
  width: 100%;
  justify-content: center;
  text-align: center;
`;

const StyledRatioBoxWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  ${breakpoint("lg")`
    padding-left: 1px;
  `}
`;

class LetterAnimations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ratioBoxHeight: 0
    }
  }

  onSize = size => {
    // console.log('Ratio box has a height of', size.height);
    this.setState({
      ratioBoxHeight: size.height
    })
  }
  render() {
    return (
      <SizeAware onSize={this.onSize}>
        <RatioBox ratio="1/1">
          <BorderGrid>
            <BorderGridCol size={{ xs: 1 / 5 }}>
              <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} fromY={-850} />
            </BorderGridCol>
            <BorderGridCol size={{ xs: 1 / 5 }}>
              <StyledLetterContainer>
                <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} headerHeight={this.props.headerHeight} reverse={true} fromY={200} />
              </StyledLetterContainer>
            </BorderGridCol>
            <BorderGridCol size={{ xs: 1 / 5 }}>
              <StyledLetterContainer>
                <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} fromY={-350} />
              </StyledLetterContainer>
            </BorderGridCol>
            <BorderGridCol size={{ xs: 1 / 5 }}>
              <StyledLetterContainer>
                <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} reverse={true} fromY={400} />
              </StyledLetterContainer>
            </BorderGridCol>
            <BorderGridCol size={{ xs: 1 / 5 }}>
              <StyledLetterContainer>
                <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} fromY={-900} />
              </StyledLetterContainer>
            </BorderGridCol>
          </BorderGrid>
        </RatioBox>
      </SizeAware>
    )
  }
}

const CardComponent = (props) => {
  const { node, locale } = props;

  return (
    <Link to={`/speaker/${node.slug.current}/`}>
      <Card href={`/speaker/${node.slug.current}/`} image={
        imageUrlFor(buildImageObj(node.listingImage))
          .width(960)
          .height(592)
          .auto('format')
          .quality(80)
          // .saturation(-100)
          .url()}
        imageHover={
          imageUrlFor(buildImageObj(node.listingImage))
            .width(960)
            .height(592)
            .auto('format')
            .quality(80)
            .saturation(-100)
            .url()}
        author={node.author}
        title={node.title ? node.title[locale] : undefined}
      />
    </Link>
  )
}

const StyledLogo = styled.div`
  img {
    width: 50%;
    height: auto;
    max-width: 437px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledBorderGridWrapper = styled.div`
  /* min-height: 50vh; */
`;

const StyledHiddenOnMobile = styled.div`
  display: block;

  ${breakpoint("md")`
    display: initial;
  `}
`;

const StyledLetterContainer = styled.div`
  position: relative;
  overflow: hidden;
  /* .content-height-minus comes from master layout */
`;


const StyledLetterSvg = styled.div`
  background-color: var(--color-beige-v2);
  padding: 5vw;
  margin: 0 2px;

  img {
    width: 100%;
  }

  ${breakpoint("md")`
    padding: 4vw;
  `}

  ${breakpoint("lg")`
    padding: 2vw;
  `}
`;

const LetterComponent = () => {
  return (
    <StyledLetterSvg>
      <img src={LetterSvg} alt="" />
    </StyledLetterSvg>
  );
};

const LetterAnimation = (props) => {
  let offset = props.letterHeight - props.ratioBoxHeight;
  let fromY = props.fromY ? String(props.fromY) : "0";
  if (props.reverse && props.fromY) offset = (props.letterHeight - props.ratioBoxHeight) - props.fromY;
  if (props.reverse && props.fromY) fromY = `-${props.fromY}`;
  const duration = props.duration ? props.duration : 96000;
  const reverse = props.reverse ? props.reverse : false;

  const animationProps = useSpring({
    from: {
      transform: `translate3d(0, ${fromY}px, 0)`
    },
    to: {
      transform: `translate3d(0, -${offset}px, 0)`
    },
    config: {
      duration: duration
    },
    reset: true,
    reverse: reverse
  });
  return <animated.div style={animationProps}>{props.children}</animated.div>
}

class LetterAnimationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containerHeight: 0,
      letterHeight: 0
    }
  }

  onSize = size => {
    // console.log('Container has a height of', size.height);
    this.setState({
      containerHeight: size.height
    })
  }

  onLetterSize = size => {
    // console.log('Letter has a height of', size.height);
    this.setState({
      letterHeight: size.height
    })
  }

  render() {
    return (
      <SizeAware onSize={this.onSize}>
        <StyledLetterContainer className={this.props.className}>
          <LetterAnimation
            ratioBoxHeight={this.props.ratioBoxHeight}
            containerHeight={this.state.containerHeight}
            letterHeight={this.state.letterHeight}
            fromY={this.props.fromY}
            duration={this.props.duration}
            reverse={this.props.reverse}
          >
            <SizeAware onSize={this.onLetterSize}>
              <LetterComponent />
            </SizeAware>
          </LetterAnimation>
        </StyledLetterContainer>
      </SizeAware>
    )
  }
}

export default injectIntl(IndexPage);
